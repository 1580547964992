<template>
    <section>
        <div class="row mx-0 j-center my-3">
            <div class="col-auto j-center d-middle f-13 text-center">
                Acceso a <br /> E-Learning 
            </div>
            <div class="col pl-0">
                <el-input v-model="buscar" class="w-100 input-radius-12" suffix-icon="icon-search" placeholder="Buscar" />
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 370px)">
            <div
            v-for="(user, u) in usuarios_mac"
            :key="u" 
            class="row mx-0"
            >
                <div class="col-12 d-middle">
                    <div class="col-2" />
                    <div class="col-auto">
                        <p class="pl-2">
                            {{ user.categoria }}
                        </p>
                    </div>
                </div>
                <router-link 
                v-for="(data, d) in user.user_x_categoria" 
                :key="d" 
                :to="{
                    name: 'configurar.usuarios.mac', 
                    params:{id_user: data.id}
                }"
                class="col-12 d-middle p-0">
                    <div class="col pl-0">
                        <div class="row mx-0 py-2 a-center br-12 px-2 cr-pointer" :class=" {'active-user':user_actual === data.id}" @click="setSelected(data.id)">
                            <i class="icon-link f-16 text-general mr-5" />
                            <img :src="data.image" width="40" height="40" class="rounded-circle obj-cover border-gris" />
                            <p class="tres-puntos col text-gris">
                                {{ data.nombre }}
                            </p>
                            <div v-if="data.admin" class="col-auto px-0">
                                <div class="bg-white border-edit tres-puntos f-18 px-3 br-12 text-gris">
                                    Admin 
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:'Mac',
    data(){
        return {
            buscar: '',
            src_image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
            user_actual: null,
            usuarios_mac: [
                {
                    categoria: 'A',
                    user_x_categoria: [
                        {
                            id: 1,
                            nombre: 'Marcos Aurelio Gutierrez',
                            cargo: 'Administrador',
                            nacimiento:'11-03-1994',
                            activo: true,
                            image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
                            admin: true
                        },
                        {
                            id: 2,
                            nombre: 'Marcos Aurelio Gutierrez',
                            cargo: 'Administrador',
                            nacimiento:'11-03-1994',
                            activo: false,
                            image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
                            admin: false
                        },
                        {
                            id: 3,
                            nombre: 'Marcos Aurelio Gutierrez',
                            cargo: 'Administrador',
                            nacimiento:'11-03-1994',
                            activo: true,
                            image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
                            admin: true
                        },
                        {
                            id: 4,
                            nombre: 'Marcos Aurelio Gutierrez',
                            cargo: 'Administrador',
                            nacimiento:'11-03-1994',
                            activo: true,
                            image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
                            admin: true
                        },
                        {
                            id: 5,
                            nombre: 'Marcos Aurelio Gutierrez',
                            cargo: 'Administrador',
                            nacimiento:'11-03-1994',
                            activo: true,
                            image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
                            admin: true
                        },
                        {
                            id: 6,
                            nombre: 'Marcos Aurelio Gutierrez',
                            cargo: 'Administrador',
                            nacimiento:'11-03-1994',
                            activo: true,
                            image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
                            admin: true
                        },
                        {
                            id: 7,
                            nombre: 'Marcos Aurelio Gutierrez',
                            cargo: 'Administrador',
                            nacimiento:'11-03-1994',
                            activo: true,
                            image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
                            admin: true
                        },
                        {
                            id: 8,
                            nombre: 'Marcos Aurelio Gutierrez',
                            cargo: 'Administrador',
                            nacimiento:'11-03-1994',
                            activo: false,
                            image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
                            admin: false
                        },
                    ]
                }
            ],
        }
    },
    methods:{
        setSelected(id_user){
            this.user_actual = id_user
        }

    }
}
</script>

<style lang="scss" scoped>
.active-user{ background-color:#F3F4F7; }
.border-edit{ border: 1px solid #DFDFDF !important; }
</style>